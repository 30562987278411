@import "../../colors.scss";

.terms_conditions {
  padding-top: 113px;
  .heading {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 3rem;
    font-weight: bold;
    color: $black;
    text-align: center;
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    margin-top: 25px;
  }
  ol {
    // padding: 0 20px;
    // counter-reset: item;
    ol {
      // padding: 0;
    }
  }
  li {
    // display: flex;
  }
  li:before {
    // content: counters(item, ".") ".";
    // counter-increment: item;
    // padding-right: 13px;
  }

  a {
    color: $primary;
    // word-break: break-all;
  }
  .text {
    padding: 0;
    font-size: 16px;
    line-height: 35px;
    color: $black;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: justify;
  }
}
@media (max-width: 992px) {
  .terms_conditions {
    .heading {
      font-size: 22px;
      margin-bottom: 2rem;
      line-height: 36px;
    }
    .text{
      text-align: inherit;
    }
  }
}
