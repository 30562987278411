@import "../../../colors.scss";

.hero-container{
  margin-top: 100px;
  .hero_wrapper{
    display: flex;
    padding: 50px 0;
    align-items: center;
    justify-content: space-between;
    .left_section{
      width: 40%; 
      max-width: 500px; 
      text-align: left;
      img{display: none;}
    }
    .right_section{width: 58%; img{width: 100%;}}
    .hero-heading {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      text-transform: uppercase;
    }
    .hero-subheading {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-top: 8px;
      letter-spacing: 0.15px;
      margin: 70px 0;
    }
    .order_now_btn{
      border: none;
      width: 100%;
      max-width: 212px;
      background-color: $primary;
      color: $white;
      font-size: 24px;
      font-weight: 500;
      padding: 16px 0;
      border-radius: 8px;
    }
  }
}

@media (max-width: 992px) {
  .hero-container {
    background: linear-gradient(360deg,rgba(0, 128, 96, 0.32) 0%,rgba(0, 128, 96, 0) 100%);
    max-width: 100%;
    padding: 0px !important;
    .hero_wrapper{
      padding: 0 0 24px 0;
      flex-direction: column;
      .left_section{
        width: 100%; 
        max-width: none;
        min-width: 320px;
        text-align: center;
        img{display: block; width: 92%; margin: 16px auto 32px auto;}
      }
      .right_section{display: none;}
      .hero-heading {
        font-size: 48px;
        line-height: 56px;
        min-width: 320px;
        width: 80%;
        margin: 0 auto;
      }
      .hero-subheading {
        font-size: 16px;
        line-height: 26px;
        padding: 0 16px;
        min-width: 320px;
        width: 80%;
        margin: 20px auto 0 auto;
      }
      .order_now_btn{
        max-width: 500px;
        width: 87%;
        padding: 10px 0;
        font-size: 20px;
      }
    }
  }
}

.no_display_sticky_btn{
  position: fixed;
  opacity: 0;
  transition: 1s;
  row-gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  bottom: -20vh;
  width: 87%;
  .sticky_btn{
    background-color: $black;
    padding: 10px 0;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
    .sticky_link{
      color: $white;
      svg{margin-left: 16px;}
    }
  }
}

.stick_btn_wrapper{
  bottom: 40px;
  opacity: 1;
  width: 87%;
  max-width: 500px;
}