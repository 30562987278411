@import "../../../colors.scss";

// .feature-scroll{
//   scroll-margin-top: 4rem;
// }
.reaches-heading {
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  color: $black;
  margin-top: 64px;
  width: 730px;
}
.reaches-playstore {
  margin-top: 97px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.features-row {
  margin: 40px 0px 40px 0px;
}
.features-title {
  margin: 16px 0px 0px 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.features-content {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin: 4px 0px;
}
.features-icon {
  position: relative;
  &:before {
    background-image: url("/assets/images/common/Line_Indicator.webp");
    content: "";
    height: 4px;
    position: absolute;
    width: calc(100% - 95px);
    // opacity: 0.4;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    // border: 1px dashed $primary;
    border-radius: 10px;
  }
}
.pictures-img {
  margin: 64px auto 0px auto;
  // width: 540px;
  width: 100%;
  height: 540px;
  object-fit: contain;
  border-radius: 8px;
  max-width: 540px;
}
.picture-heading {
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
  max-width: 444px;
}
.picture-subtitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  margin: 32px 0px;
  max-width: 444px;
}
.picture-download {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: $primary;
}
.features-col:nth-child(1) {
  padding-left: 0px;
}
.features-col:nth-child(4) {
  .features-icon {
    &::before {
      display: none;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .features-col:nth-child(3) {
    .features-icon {
      &::before {
        display: none;
      }
    }
  }
  .features-col {
    padding: 0px;
  }
}
@media (max-width: 992px) {
  .reaches-heading {
    width: 100%;
    font-size: 32px;
    line-height: 48px;
    margin-top: 32px;
  }
  .features-row {
    margin: 20px 0px 20px 0px;
  }
  .features-col {
    display: flex;
    margin: 32px 0px;
    padding: 0px;
    .features-text {
      margin: 0px 16px;
      .features-title {
        margin: 0px;
      }
    }
  }
  .features-icon {
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      right: 50%;
      top: 71px;
      transform: translateX(-50%);
      height: calc(100% - 16px);
      z-index: 1;
      background-image: url("/assets/images/common/vertical_line.webp");
    }
  }
  .features-col:last-child {
    .features-icon {
      &::before {
        display: none;
      }
    }
  }
  .features-icon svg {
    position: relative;
    z-index: 2;
  }
  .pictures-img {
    margin: 29px 0px;
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  .picture-heading {
    margin: 12px 0px;
    font-size: 24px;
    line-height: 32px;
  }
  .picture-subtitle {
    margin: 8px 0px;
    font-size: 16px;
    line-height: 26px;
  }
}
