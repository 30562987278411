.justR_products{
  padding-top: 80px;
  padding-bottom: 80px;
  .heading{
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .img_container{
    display: flex;
    column-gap: 24px;
    justify-content: center;
    margin: 0 auto;
    margin-top: 48px;
    .img_wrapper{
      position: relative;
      img{
        width: 100%;
        max-width: 552px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        border-radius: 8px;
      }
      .img_title{
        font-size: 22px;
        font-weight: 700;
        color: #ffffff;
        text-align: left;
        position: absolute;
        margin: 0;
        bottom: 20px;
        left: 20px;
        cursor: default;
      }

      &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        border-radius: 4px;
        opacity: 1;
        transition: all .2s ease-in;
      }

      &:hover {
        &::before {
            opacity: 0;
        }
        .img_title {
              // color: #FFD348;
              opacity: 0;
          }
      }
    }
  }
}

@media(max-width: 1200px){
  .justR_products{
    .heading{
      font-size: 32px;
    }
  }
}

@media (max-width: 1001px){
  .justR_products{
    .img_container{
      div{
        display: flex;
        justify-content: center;
        column-gap: 16px;
        img{width: 45%;}
      }
      .img_wrapper{display: none;}
    }
  }
}