.justR_hero{
  margin-top: 72px;
  height: calc(100vh - 72px);
  position: relative;
  .hero_img{width: 100%; position: absolute;}
  .top_img{left: 0; top: 0;}
  .bottom_img{left: 0; bottom: 0;}
  .container{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hero_heading{
    font-size: 60px;
    font-weight: 400;
  }
  .hero_description{
    font-size: 20px;
    letter-spacing: 0.15px;
    margin-top: 28px;
  }
}

@media(max-width: 992px){
  .justR_hero{
    .top_img{
      transform: scale(2);
      top: 40px;}
    .bottom_img{
      transform: scale(2.5);
      bottom: 44px;}
    .hero_heading{
      font-size: 32px;
    }
    .hero_description{
      font-size: 16px;
      letter-spacing: 0.15px;
      margin-top: 20px;
    }
  }
}

@media (max-height: 450px){
  .justR_hero{
    height: auto;
    position: static;
    margin-bottom: 80px;
    .hero_img{width: 100%; position: static;}
    .container{
      position: static;
      transform: translate(0%, 0%);
      margin-bottom: 120px;
      margin-top: 80px;
    }
  }
}