@import '../../colors.scss';

.privacy {
  padding-top: 113px;
.text-justify{
  text-align:justify;
}
.li_data_retation {
  margin-top: 1rem;
}
  .heading {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 3rem;
    font-weight: bold;
    color: $black;
    text-align: center;
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    margin: 35px 0px;
  }
  a {
    color: $primary;
  }
  .text {
    padding: 0;
    font-size: 16px;
    // line-height: 47px;
    color: $black;
    margin-top: 1rem;
    position: relative;
    // text-align: justify;
  }
  .li_text {
    padding: 0;
    font-size: 16px;
    line-height: 26px;
    color: $black;
    // margin-top: 1rem;
    position: relative;
    .li_subtext {
      line-height: 35px;
    }
  }

  ol {
    // counter-reset: item;
  }
  li {
    // display: flex;
  }
  li:before {
    // content: counters(item, ".");
    // counter-increment: item;
    // padding-right: 13px;
  }
  .li_txt {
    margin-bottom: 1rem;
    // line-height: 47px;
    // text-align: justify;
  }
  .li_txt_sub {
    margin-bottom: 0;
  }


}
@media (max-width: 992px) {
  .privacy {
    .heading {
      font-size: 22px;
      margin-bottom: 2rem;
      line-height: 36px;
    }
  }
}
