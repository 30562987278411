@import "../../colors.scss";
.navbar {
  height: 72px;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  background: $white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;
    .nav-item > .nav-link {
    color: $black !important;
    margin: 0px 16px;
    :hover {
      color: $black !important;
    }
  }
}

.logo svg {
  height: 39px;
  width: auto;
  margin: 0px 16px 0px 0px;
}

.navBtnLink .playstore {
  height: 21px;
  margin: 0px 14px;
}

.menu_icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
li .cross_icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.cross_icon {
  height: 56px;
  text-align: start;
}
.nav_app_link_container{
  display: flex;
  column-gap: 16px;
}

.navbar_order_now{
  display: none;
}

@media(max-width: 1399px){
  .navbar {
    .nav-item > .nav-link {
      margin: 0px;
    }
  }
}

@media (max-width: 992px) {
  .navbar-nav,
  .navBtnLink {
    display: block !important;
    position: fixed;
    top: -200%;
    transition: top 0.9s;
    padding: 24px;
    left: 0;
    padding-top: 21px;
    z-index: 10;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
    width: 100%;
    text-align: center;
  }
  .box-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    background: $white !important;
  }
  .logo svg {
    height: 30px;
  }
  .navbar_order_now{
    display: block;
    padding: 8px 0;
    p{margin-bottom: 0 !important;}
  }
 
}
@media (min-width: 992px) {
  .menu_icon ,
  li .cross_icon {
    display: none;
  }
  .navbar {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
}

.active {
  top: 0;
  transition: top 0.5s;
}

.navBtnLink {
  padding: 10px 22px;
  background: $black;
  border-radius: 4px;
  text-decoration: none;
  outline: none;
  border: none;
  font-size: 16px;
  white-space: nowrap;
  color: $white;
  cursor: pointer;
  border: 1px solid $black;
  display: block;
}

.navBtnLink.home {
  padding: 10px 22px;
  background: $primary;
  border-radius: 4px;
  text-decoration: none;
  outline: none;
  border: none;
  font-size: 16px;
  white-space: nowrap;
  color: $white;
  cursor: pointer;
}

.navBtnLink:hover {
  background: transparent;
  color: $black !important;
}
.navBtnLink.home:hover {
  background: transparent;
  border: 1px solid $primary;
  color: $primary !important;
}
.navBtnLink svg {
  margin: 0px 16px;
}

@media(max-width: 1399px){
  .navBtnLink {
    padding: 10px;
  }
}
