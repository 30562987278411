.justR_whyJust {
  padding: 80px 0;
  background-color: #008060;
  .container h2 {
    font-weight: 700;
    font-size: 48px;
    color: #fff;
    text-align: center;
    margin-bottom: 92px;
    text-transform: uppercase;
  }
  .container {
    .list {
      display: flex;
      justify-content: space-between;
    }
    .item {
      width: 32%;
    }
    .item img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
    .item h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        color: #fff;
        margin-top: 62px;
        margin-bottom: 12px;
      }
    .item p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #dadada;
    }
  }
}
@media (max-width: 750px) {
  .justR_whyJust {
    padding: 64px 0;
    .container h2 {
      font-size: 32px;
      margin-bottom: 68px;
    }
    .container {
      .list {
        flex-direction: column;
        row-gap: 72px;
      }
      .item {
        width: 100%;
      }
      .item h3 {
        margin-top: 24px;
      }
    }
  }
}
