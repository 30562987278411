.justR_presence {
  padding-top: 80px;

  @media (max-width: 600px) {
    padding-top: 0;
  }
  .container {
    h2 {
      color: #25282b;
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      line-height: 58px;
      text-transform: uppercase;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #0f0f0f;
    }
    .partners {
      width: 100%;
      text-align: center;
      margin-top: 50px;
    }

    .partner {
      display: inline-block;
      padding: 23px 12px;
    }

    .partner-SecondLast img {
      display: none;
    }
    .partner-last img {
      display: none;
    }
    .partner-lots {
      display: inline-block;
      padding: 23px 12px;
    }

    @media (max-width: 800px) {
      h2{
        font-size: 32px;
      }
      .partners {
        margin-top: 20px;
      }
      .partner-SecondLast img {
        width: 100%;
        display: inline-block;
        padding: 23px 62px;
      }
      .partner-lots {
        display: none;
      }
      .partner-last img {
        width: 100%;
        display: inline-block;
        padding: 23px 62px;
      }
    }

    @media (max-width: 767px) {
      .partner {
        width: 49%;
        padding: 6px;
      }
    }

    .partner img {
      border-radius: 4px;
    }

    @media (max-width: 991px) {
      .partner img {
        width: auto;
      }
    }

    @media (max-width: 767px) {
      .partner img {
        width: 100%;
      }
      .partner-SecondLast img {
        width: 100%;
        display: inline-block;
        padding: 6px;
      }
      .partner-last img {
        width: 100%;
        display: inline-block;
        padding: 6px;
      }
    }
  }
}
