@import "./styles.scss";

// .benefitsSlider {
//   height: 650px;
// }
.sliderContent {
  display: flex !important;
  flex-direction: row;
  @media screen and (max-width: $medium) {
    width: 100%;
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    // align-items: center;
    position: relative;
  }
}

.bContent {
  margin-top: 32px;
  margin-left: 4.875rem;
  @media screen and (max-width: $medium) {
    margin-left: 0;
  }
}
.bTitle {
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
}
.bSubtitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  margin: 32px 0px;
  margin-left: 4.875rem;
  @media screen and (max-width: $medium) {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.bSliderImage {
  max-width: 100%;
  img {
    object-fit: fill;
    height: 512px;
    width: 512px;
    @media screen and (max-width: $medium) {
      width: 350px;
      height: auto;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  @media screen and (max-width: $medium) {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}

.bgTitle {
  max-width: 27.5rem;
  margin-top: 48px;
  @media screen and (max-width: $medium) {
    max-width: 100%;
    margin-top: 10px;
  }
}

.dMsg {
  max-width: 28rem;
  margin-top: 48px;
  @media screen and (max-width: $medium) {
    max-width: 100%;
    margin-top: 10px;
  }
}

.dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: -3rem;
  position: relative;
  width: 100%;
  justify-content: center;

  @media only screen and (min-width: $medium) and (max-width: $large) {
    width: 50%;
    justify-content: unset;
    padding-left: 66%;
  }

  @media screen and (max-width: $medium) {
    margin-left: auto;
    margin-right: auto;
    margin-top: -15rem;
    justify-content: center;
  }
  li {
    margin-right: 6px;
    &:before {
      content: "";
      height: 8px;
      width: 8px;
      display: inline-block;
      background: rgba(0, 0, 0, 0.24);
      border-radius: 6px;
      cursor: pointer;
      transition: all 300ms ease;
    }
  }
  li.active {
    &:before {
      width: 16px;
      background: $primary;
    }
  }
}
