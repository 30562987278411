.justR_footer{
  background-color: #008060;
  padding: 40px 0;
  .container{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .download_heading{font-size: 32px; font-weight: 700; color: #fff; line-height: 40px;}
  .playstore{margin: 0;}
  .right_section, .left_section{text-align: left;}
  .just_follow{font-size: 20px; color: #fff;}
  .social_img{width: 30px; object-fit: contain;}
  .right_section{
    .for_mob{display: none;}
    a{margin-right: 30px;}
  }
}

@media (max-width: 500px) {
  .justR_footer{
    .download_heading{font-size: 24px; line-height: 32px;}
    .just_follow{font-size: 14px;}
    .right_section{
      text-align: right;
      .for_mob{display: block;}
      a{margin-right: 16px;}
    }
  }
}