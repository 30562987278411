@import "./colors.scss";
$medium: 993px;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Encode Sans Expanded', sans-serif; */
  // font-family: 'Rubik', sans-serif;
}

html,
body{
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background: $white;
}

a {
  cursor: pointer;
  text-decoration: none;
  &:hover{
    color: $primary !important;
  }
}
.playstore{
  padding: 0px !important;
  img {
    
    height: 40px;
    // &:last-child{
    //   display: none;
    // }
  }
  // &:hover{
  //   img{
  //     &:first-child{
  //       display: none;
  //     }
  //     &:last-child{
  //       display: block;
  //     }
  //   }
  // }
}
.mt-8{
  margin-top:8rem;
  @media screen and (max-width: $medium) {
    margin-top: 4.5rem;
  }
}
// .scroll{
//   scroll-margin-top: 8rem;
//   // scroll-margin-bottom: 22rem;

// }