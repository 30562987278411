.justR_justApp{
  padding: 100px 0;
  .justapp_heading{
    font-size: 32px; 
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    padding-bottom: 20px;
    .for_mob{display: none;}
  }
  .just_slick_slider{margin-top: 40px;}
  .slick-list{width: 90%; margin: 0 auto;}
  .slider_img{
    margin: 0 auto;
    width: 250px !important;
  }
  .slick-prev{
    &::before{color: black; font-size: 30px;}
  }
  .slick-next{
    &::before{color: black; font-size: 30px;}
  }
  .slick-dots{
    bottom: -100px;
    li{
      width: 90px;
      button{
        width: 90px;
        &::before{
          width: 90px;
          height: 5px;
          content: "";
          background-color: rgba(1, 1, 1, 0.3);
          border-radius: 3px;
        }
      }
    }
    li.slick-active{
      button{
        &::before{
          background-color: #008060;
        }
      }
    }
  }
}

@media(max-width: 992px){
  .justR_justApp{
    padding: 50px 0;
    .justapp_heading{
      line-height: 50px;
    }
    .slick-list{width: 100%;}
  }
}

@media(max-width: 768px){
  .justR_justApp{
    padding: 30px 0;
    .justapp_heading{
      border-bottom: none;
      padding-bottom: 0px;
      line-height: 40px;
      .for_web{display: none;}
      .for_mob{display: block;}
    }
    .slider_img{
      width: 200px !important;
    }
    .just_slick_slider{margin-top: 20px;}
    .slick-list{width: 100%; margin: 0 auto;}
    .slick-dots{
      bottom: -65px;
      li{
        width: 37px;
        button{
          width: 37px;
          &::before{
            width: 37px;
            height: 4px;
          }
        }
      }
    }
  }
}