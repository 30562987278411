.justR_about{
    padding: 80px 0;
    .container img{
        width: 100%;
        height: auto;
    }
    .container p{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-top: 40px;
        text-align: center;
        color:#0F0F0F;
    }
}
@media (max-width:500px) {
    .justR_about{
        padding-bottom: 10px;
        padding-top: 25px;
        .container p{
            padding-top: 16px;
        }
    }
}