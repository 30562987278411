@import "../../colors.scss";

.footer {
  background-color: $black;
  color: $white;
  padding: 56px 0;
  z-index: 10;
  position: relative;
  .just_logo {
    width: 48px;
  }
  .flex_conatiner {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .left_section {
      .link {
        display: flex;
        align-items: center;
        column-gap: 16px;
        margin-bottom: 22px;

        a {
          font-weight: 500;
          color: $white;
        }
      }
      .follow_us {
        font-size: 14px;
        font-weight: 400;
      }
      .powered_by {
        font-size: 14px;
        font-weight: 400;
      }
      .call_icon {
        width: 18px;
      }
      .email_icon {
        width: 20px;
      }
      .location_icon {
        width: 14px;
      }
      .fb_logo {
        width: 28px;
        margin-right: 16px;
        cursor: pointer;
      }
      .bijak_logo {
        width: 75px;
        margin-left: 5px;
      }
      
      .rights_reserved_web {
        font-size: 14px;
        font-weight: 400;
        color: rgba($color: #ffffff, $alpha: 0.48);
      }
    }

    .right_section {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .page_link {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        a {
          color: $white;
        }
        .mob_follow_us {
          display: none;
        }
      }
      .app_link {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .get_the_app {
          display: none;
        }
        .rights_reserved_mob {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .footer {
    padding: 25px 15px;
    .flex_conatiner {
      flex-direction: column;
      .left_section {
        margin-bottom: 40px;
        margin-top: 40px;
        .follow_us {
          display: none;
        }
        .rights_reserved_web {
          display: none;
        }
      }

      .right_section {
        .page_link {
          .mob_follow_us {
            display: block;
            font-size: 14px;
            margin-top: 10px;
            div {
              margin-top: 14px;
            }
            img {
              width: 30px;
              margin-right: 16px;
            }
          }
        }
     
        .app_link {
          margin-top: 40px;
          align-items: center;
          .get_the_app {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            display: block;
          }
          .rights_reserved_mob {
            display: block;
            font-size: 14px;
            font-weight: 400;
            color: rgba($color: #ffffff, $alpha: 0.48);
            margin-top: 10px;
          }
        }
      }
    }
  }
}
