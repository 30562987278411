.justR_getInTouch{
  padding: 80px 0;
  .container{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    .left_section{
      width: 50%; 
      max-width: 500px;
      text-align: left;
      .heading{
        font-size: 48px; 
        font-weight: 500; 
        text-transform: uppercase;
        padding-left: 8px;
      }
      p{
        padding-left: 8px;
        color:#25282B;
        margin-bottom: 40px;
      }
    }
    .right_section{
      width: 50%;
      img{width: 100%;}
    }
  }
}

@media(max-width: 700px){
  .justR_getInTouch{
    .container{
      flex-direction: column;
      .left_section{
        width: 100%; 
        text-align: center;
        .heading{font-size: 32px;}
      }
      .right_section{
        display: none;
      }
    }
  }
}