@import "../../../colors.scss";
.message-container {
  height: 148px;
  background: $primary;
  color: $white;
}
.message-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.message-input {
  height: 52px;
  border-radius: 4px;
  background: $white;
  width: 100%;
  box-shadow: none;
  padding: 14px 16px;
  outline: none;
  border: none;
}
.position-relative {
  position: relative;
}
.message-button {
  padding: 14px 28px;
  width: 176px;
  height: 52px;
  align-items: center;
  background: $black;
  border-radius: 4px;
  color: $white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border-color: $black;
  display: flex;
  svg {
    margin: 0px 10px;
    height: 24px;
    width: 24px;
  }
  i{
    font-size: 21px;
    margin: 2px 12px;
  }
}
.error-message {
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #ffff;
  position: absolute;
  bottom: -31px;
}
.flex-1 {
  flex: 1;
}
.message-container .container {
  column-gap: 15px;
}
@media (max-width: 992px) {
  .message-container {
    display: none;
  }
}
