@import "../../../colors.scss";
$medium: 993px;
$small: 500px;

.tContainer {
  
  @media screen and (max-width: $medium) {
    margin-top: 0rem !important;
  }
}
.theading {
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  margin: 8px 0px;
  @media screen and (max-width: $medium) {
    font-size: 32px;
    line-height: 48px;
  }
}
.tsubheading {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  margin: 8px 0px;
}
.tcard {
  position: relative;
  display: flex !important;
  flex-direction: column;
  margin: 20px auto;
  padding: 70px;
  min-height: 252px;
  box-shadow: 0px 2px 30px rgba(102, 102, 102, 0.16);
  border-radius: 8px;
  text-align: center;
  justify-content:center;
  width: 89% !important;

  @media screen and (max-width: $medium) {
    padding: 20px;
    min-height: 350px;
  }
  .quote1,
  .quote2 {
    position: absolute;
    width: 46px;
    height: 46px;
    width: 46px;
    height: 46px;
    @media screen and (max-width: $medium) {
      width: 20px;
      height: 20px;
    }
  }
  .quote1 {
    top: -48px;
    left: -23px;
    @media screen and (max-width: $medium) {
      top: -22px;
      left: 0px;
    }
  }
  .quote2 {
    bottom: -18px;
    right: -40px;
    @media screen and (max-width: $medium) {
      bottom: -18px;
      right: 0px;
    }
  }
  .ttext {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    position: relative;
    text-align: center;
    @media screen and (max-width: $medium) {
      font-size: 15px;
      margin: 20px auto;
    }
  }
  .tname {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0px;
    margin-left: 49%;
    margin-bottom: -14px;
    box-sizing: border-box;
    border: 0.5rem solid $black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    left: 0;
    box-shadow: -3px 3px 3px 0 rgb(102 102 102 / 0.16);
    @media screen and (max-width: $small) {
      margin-left: 46%;
    }
  }
}

.tThumbSlider {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100px;
  img {
    height: 56px;
    width: 56px;
  }
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

.tThumbSlider.active {
  transition: all 0.3s ease-in-out;
  transform: scale(1.5);
}

.thumbSliderWrapper {
  width: 40%;
  margin: 0 auto;
  @media screen and (max-width: 1030px) {
    width: 58%;
  }
  @media screen and (max-width: 1000px) {
    width: 78%;
  }
  @media screen and (max-width: $small) {
    width: 100%;
  }
}
