@import "../../../colors.scss";
.infobox {
  height: 491px;
  background: $primary;
  .iheading {
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    color: $white;
    margin: 68px 0px;
    width: 550px;
  }
  .large {
    height: 491px;
    width: 100%;
    object-fit: cover;
    object-position: left;
  }
  .small {
    display: none;
  }
}
@media (max-width: 992px) {
  .infobox {
    height: 100%;
    .iheading {
      font-size: 24px;
      line-height: 36px;
      width: 100%;
      font-weight: 500;
      margin: 38px 0px 0px 0px;
    }
    .large {
      display: none;
    }
    .small {
      display: block;
      // height: 513px;
      max-width: 100%;
      margin-left: auto;
      // width: 100%;
      object-fit: contain;
      object-position: left;
    }
  }
}
